import { m as motion, useTransform, useViewportScroll } from "framer-motion";
import React, { useEffect, useState } from "react";
import theme, { TOOLBAR_HEIGHT } from "../../gatsby-theme-material-ui-top-layout/theme";

const defaultPageProgressProps = {
  background: theme.palette.primary.main,
  // this is xs height (non-xs height is 1px bigger)
  height: 4
};

export type PageProgressProps = Partial<typeof defaultPageProgressProps>;

/** A linear progress with custom height and border radius */
const PageProgress: React.FC<PageProgressProps> = (props) => {
  props = {
    ...defaultPageProgressProps,
    ...props
  };
  const [contentHeight, setContentHeight] = useState(0);
  const { scrollY } = useViewportScroll();
  const scaleX = useTransform(scrollY, (y) => contentHeight && y / contentHeight);
  const { height } = props;

  useEffect(() => {
    const updateContentHeight = () => {
      const bodyHeight = document.body.clientHeight;
      const footerHeight = document.querySelector("footer")?.offsetHeight || 0;
      setContentHeight(bodyHeight - footerHeight - window.innerHeight);
    };
    updateContentHeight();
    window.addEventListener("resize", updateContentHeight);
    return () => window.removeEventListener("resize", updateContentHeight);
  }, []);

  return (
    <motion.div
      style={{
        position: "fixed",
        top: TOOLBAR_HEIGHT,
        left: 0,
        right: 0,
        scaleX,
        zIndex: 5,
        transformOrigin: "left center",
        borderRadius: `0 0 ${height}px 0`,
        height,
        background: props.background
      }}
    />
  );
};

export default PageProgress;
