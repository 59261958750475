import { Container, Typography } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@placehires/react-component-library";
import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import readingTime from "reading-time/lib/reading-time";
import RenderMarkdown from "../cms/RenderMarkdown";
import PageProgress from "../components/progress/PageProgress";
import { blogTheme } from "../gatsby-theme-material-ui-top-layout/theme";
import PublicLayout from "../layout/PublicLayout";
import { formatDistanceToNowWithoutApproximation } from "../utils/utils";

type BlogPostTemplateProps = {
  content: string;
  summary: string;
  author: string;
  title: string;
  date: string;
  preview?: boolean;
  // Used when not in preview
  featuredImage?: IGatsbyImageData;
  // Used when in preview
  imgSrc?: string;
};

export const BlogPostTemplate: React.FC<BlogPostTemplateProps> = ({
  content = "",
  summary,
  author,
  title,
  date,
  preview,
  featuredImage,
  imgSrc
}) => {
  const { classes } = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={blogTheme}>
        <div className={classes.intro}>
          <Typography variant="h2" align="center" className={classes.title} color="primary">
            {title}
          </Typography>
          <Typography color="textSecondary" className={classes.summary}>
            {summary}
          </Typography>
          <div className={classes.authorDate}>
            <Typography color="primary" variant="h6" className={classes.author}>
              {author}
            </Typography>
            <Typography color="textSecondary">
              Updated{" "}
              {formatDistanceToNowWithoutApproximation(new Date(date), {
                addSuffix: true
              })}{" "}
              &middot; {readingTime(content).text}
            </Typography>
          </div>
        </div>
        <div className={classes.featuredImage}>
          {!preview ? (
            <GatsbyImage image={featuredImage} loading="eager" alt="" />
          ) : (
            <img src={imgSrc} alt="preview" className={classes.img} />
          )}
        </div>
        <div className={classes.body}>
          <RenderMarkdown runtime={preview}>{content}</RenderMarkdown>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

type BlogPostProps = {
  data: any;
};

const BlogPost: React.FC<BlogPostProps> = ({ data }) => {
  const {
    mdx: {
      body,
      fields: {
        frontmatter: { author, title, date, summary, featuredImage }
      }
    }
  } = data;
  return (
    <PublicLayout seoTitle={title} stickyHeader bodyPadding>
      <Container>
        <PageProgress />
        <BlogPostTemplate
          content={body}
          author={author}
          title={title}
          date={date}
          summary={summary}
          featuredImage={featuredImage.childImageSharp.gatsbyImageData}
        />
      </Container>
    </PublicLayout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query MyQuery($id: String!) {
    mdx(id: { eq: $id }) {
      body
      slug
      fields {
        frontmatter {
          title
          author
          date
          summary
          featuredImage {
            ...fluidImage
          }
        }
      }
    }
  }
`;

const postWidth = {
  maxWidth: 780,
  margin: "auto"
};

const useStyles = makeStyles()((theme) => ({
  intro: {
    ...postWidth
  },
  title: {},
  summary: {
    marginTop: theme.spacing(2)
  },
  authorDate: {
    display: "flex",
    margin: theme.spacing(2, 0, 2.5)
  },
  author: {
    marginRight: theme.spacing(1.5)
  },
  featuredImage: {
    maxWidth: 930,
    margin: "auto"
  },
  img: {
    width: "100%"
  },
  body: {
    paddingTop: theme.spacing(0.5),
    ...postWidth
  }
}));
